// THIS FILE IS AUTOGENERATED

export interface Version {
  commit: string;
  version: string;
  app: string;
}

export const version = {
  app: "myqq",
  commit: "728bf4eeaa132eb5eaa5db228c248773fad127a7",
  version: "1.102.4",
};
